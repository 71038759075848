 body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.login-container {
    max-width: 400px;
    margin: 200px 0 0 0;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.login-header h1 {
    text-align: center;
    margin-bottom: 20px;
}

.login-form form {
    display: grid;
    max-width: 30%;
    margin: 100px auto;
    gap: 1px;
    padding: 1rem;
    border-radius: 5px;
    background-color: rgba(35, 69, 103, 0.3);
    
}

.login-form form label {
    font-size: 16px;
    font-weight: bold;
}

.login-form 
input[type="text"],
.login-form  input[type="email"]
 {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

.login-form  button{
font-size: 17px;
padding: 1.5em 2em;
border: transparent;
box-shadow: 2px 2px 4px rgba(0,0,0,0.4);
background: dodgerblue;
color: white;
border-radius: 4px;
margin: 0 auto;
display: block; 
font-weight: bold;
}




@media (max-width: 448px) {
    .login-container {
        max-width: 100%;
        margin: 50px auto;
    }

   .login-header h1 {
        font-size: 24px;
        font-weight: bold;
       
    }

    .login-form  label,
    .login-form  input[type="text"],
    .login-form  input[type="email"],
    .login-form  button {
        font-size: 14px;
    }
    .login-form form{
       
        max-width: 400px;
    }
   .login{
    width: 250px;
    margin: 0 0 0 70px;
   }
}
