.checkbox-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .checkbox-item {
    width: 50%; 
    box-sizing: border-box;
    padding: 5px;
  }
  
  .checkbox-item input {
    margin-right: 5px;
  }

  
.container {
  width: 100%;
  margin: 0 auto;
}

.header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
  background-color: lightgray;
  padding: 8px;
  text-align: center;
}

.feature {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.label-container {
  width: 300px;
}

.input-container {
  display: flex;
  gap: 10px;
}

#regbtn{
  margin: 0 0 0 150px;
  width:300px
}

#details_form{
  width: 20%;
}

#details_main{
  width: 650px;
  height: 230vh;
  margin: 0 0 0 300px;
}


@media only screen and (max-width:1024px){
 #page{
  width: 100px;
 }

  #details_main{ 
    width: 500px;
    height: 110vh;
    margin: 150px 0 0 300px;
  }

  #page_box{
    width: 500px;
  }

  #details_form{
    width:50%;
  }

  .container{
    margin: 0px 0 0 0;
    width: 100%;
  }

  #subform{
    width:30%
  }

  #regbtn{
    margin: 0 0 0 20px;
  }

  #root{
    width: 700px;
  }
  
}

@media only screen and (max-width:768px){
  #page{
  width: 100px;
 }

  #details_main{ 
    width: 500px;
    height: 160vh;
    margin: 150px 0 0 150px;
  }

  #page_box{
    width: 500px;
  }

  #details_form{
    width:50%;
  }

  .container{
    margin: 0px 0 0 0;
    width: 100%;
  }

  #subform{
    width:30%
  }

  #regbtn{
    margin: 0 0 0 20px;
  }
}
@media only screen and (max-width:450px){
  #page{
    width: 100px;
   }
  
    #details_main{ 
      width: 500px;
      height: 146vh;
      margin: 155px 0 0 -10px;
    }
  
    #page_box{
      width: 500px;
    }
  
    #details_form{
      width:50%;
    }
  
    .container{
      margin: 0px 0 0 0;
      width: 100%;
    }
  
    #subform{
      width:30%
    }
  
    #regbtn{
      margin: 0 0 0 60px;
    }
    #root{
      width: 150px;
    }
}