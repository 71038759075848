table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

th, td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 5px;
    height: auto; 
    width:10px;
    
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

th {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
}

tr:hover {
    background-color: #ddd;
}

.checkdata1,.checkdata2{
    padding:5px;
    border-radius: 5px;
    background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, lightgray 100%);
    font-weight: bold;
    outline: none;
    color: rgb(9, 36, 37);
}


#cp_user{
    font-weight: bold;
    background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, lightgray 100%);
    border-radius: 5px;
}
.assignbtn{
    padding: 5px;
    margin: 0 0 0 50px;
    border-radius: 5px;
    background: #123456;
    color: #fff;
}

#test-table-xls-button{
    background: green;
    border-radius: 5px;
    padding: 2px;
    color: white;
}

@media screen and (max-width: 1024px){
    table {
        border-collapse: collapse;
        width: 100%;
        margin-top: 20px;
    }
    
    th, td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 5px;
        height: auto; 
        width:10px;
        
    }
    
    tr:nth-child(even) {
        background-color: #f2f2f2;
    }
    
    th {
        background-color: #007bff;
        color: white;
        font-weight: bold;
        text-transform: uppercase;
    }
    
    tr:hover {
        background-color: #ddd;
    }
    
    .checkdata1,.checkdata2{
        padding:5px;
        border-radius: 5px;
        background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, lightgray 100%);
        font-weight: bold;
        outline: none;
        color: rgb(9, 36, 37);
    }
    
    
    #cp_user{
        font-weight: bold;
        background: linear-gradient(180deg, rgba(0,0,0,0.4) 0%, lightgray 100%);
        border-radius: 5px;
    }
    .assignbtn{
        padding: 5px;
        margin: 0 0 0 50px;
        border-radius: 5px;
        background: #123456;
        color: #fff;
    }
    
    #test-table-xls-button{
        background: green;
        border-radius: 5px;
        padding: 2px;
        color: white;
    }
     
}