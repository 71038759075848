
#data_button{
    margin:100px 0 0 0;
}


    #details{
        margin-Left: 1000px;
    }

    #table_view{
        margin: -25px 0 0 20px
    }


@media only screen and (max-width:768px){
    #add{
        margin:100px 0 0 -980px;
    }

    #list{
        width: 800px;
    }

    .checkdata1{
        width: 80px;
        
    }

    .checkdata2{
        width: 150px;
    }

    #table_view{
        margin:50px 0 0 20px;
    }
}