  /* header{
    position: fixed;
    top:0;
    width: 100%;

   
  }

  #side_nav{
    display: flex;
    gap:20px;
  }
  @media (max-width:'400px') {
    #btnlogout{
        font-size: 5px;
    }
  }

  .Btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 35px;
    height: 35px;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition-duration: .3s;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
    background-color:grey;
    margin:10px 0 0 0;
  }
  
 
  .sign {
    width: 100%;
    transition-duration: .3s;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .sign svg {
    width: 17px;
  }
  
  .sign svg path {
    fill: white;
  }
 
  .text {
    position: absolute;
    right: 0%;
    width: 0%;
    opacity: 0;
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    transition-duration: .3s;
  }
  
  .Btn:hover {
    width: 125px;
    border-radius: 40px;
    transition-duration: .3s;
  }
  
  .Btn:hover .sign {
    width: 10%;
    transition-duration: .3s;
    padding-left: 20px;
  }
  
  .Btn:hover .text {
    opacity: 1;
    width: 70%;
    transition-duration: .3s;
    padding-right: 10px;
  }
  
  .Btn:active {
    transform: translate(2px ,2px);
  }

  @media print {
    
    body {
      overflow: hidden !important;
    }
  } */

/* Responsive header styles */
#head {
  position: fixed;
  top: 0;
  height:40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

#side_nav {
  display: flex;
  gap: 20px;
  margin-right:30px;
}

@media only screen and (max-width: 1024px) {
  /* Adjust styles for smaller devices */
  .Btn {
    font-size: 12px; /* Example size, adjust as needed */
  }
  #side_nav h3{
    font-size: 15px;
    width: 50px;
  }

  #head{
    position: fixed;
  top: 0;
  height:40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
}


/* Button styles */
.Btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 35px;
  height: 35px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: 0.3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: grey;
  margin: 10px 0 0 0;
}

/* Plus sign */
.sign {
  width: 100%;
  transition-duration: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign svg {
  width: 17px;
}

.sign svg path {
  fill: white;
}

/* Text */
.text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  transition-duration: 0.3s;
}

/* Hover effect on button width */
.Btn:hover {
  width: 125px;
  border-radius: 40px;
  transition-duration: 0.3s;
}

.Btn:hover .sign {
  width: 10%;
  transition-duration: 0.3s;
  padding-left: 20px;
}

/* Hover effect button's text */
.Btn:hover .text {
  opacity: 1;
  width: 70%;
  transition-duration: 0.3s;
  padding-right: 10px;
}

/* Button click effect */
.Btn:active {
  transform: translate(2px, 2px);
}

@media print {
  /* Ensure all content is visible when printing */
  body {
    overflow: hidden !important;
  }
}

  