.partner-form {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .form-group {
    flex: 1;
  }
  
  .form-group label,
  .form-group input,
  .form-group textarea {
    width: 100%;
    display: block;
  }
  
  button[type='submit'] {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button[type='submit']:hover {
    background-color: #0056b3;
  }
  